<template>
  <div>
    <!-- <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">To</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control is-expanded">
            <vue-input-tag :tags.sync="innerEmailer.toEmails"
              @new-tag="newEmailInput"
              validate="email"
              tabindex="1" />
            <span class="help is-danger"
              v-if="!isValidToEmail">Invalid email address</span>
            <span class="help is-info"
              v-if="showToHint">Press &lt;Enter&gt; key to add email address</span>
          </div>
        </div>
        <div class="control">
          <button class="button"
            @click="toggleCcBcc"
            tabindex="-1">
            <span class="icon is-small is-left">
              <i class="mdi mdi-24px"
                :class="[ showCcBcc ? 'mdi-chevron-up' : 'mdi-chevron-down' ]" />
            </span>
          </button>
        </div>
      </div>
    </div> -->
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">To</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control is-expanded">
            <multiselect id="toemails-multiselect"
              v-model="selectedToEmails"
              :options="availableToEmails"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="true"
              :preserve-search="true"
              :taggable="true"
              placeholder="Enter email address or search"
              tag-placeholder="Press enter to add email"
              label="email"
              track-by="email"
              :show-labels="false"
              @tag="addEmailTag"
              @search-change="getAssetsEmailAddresses"
              :searchable="true"
              :internal-search="false">
              <template slot="option"
                slot-scope="props">
                <span class="tag is-pulled-right"
                  :class="{'is-success' : props.option.assetTypeName === 'Customer', 'is-info' : props.option.assetTypeName === 'Insurer'}">
                  {{ props.option.assetTypeName }}
                </span>
                <span>{{ props.option.name }} &lt;{{ props.option.email }}&gt;</span>
              </template>
              <template slot="tag"
                slot-scope="props">
                <span class="tag is-primary multiselect-email-tag">
                  {{ props.option.email }}
                  <button class="delete is-small"
                    @click="props.remove(props.option)" />
                </span>
              </template>
            </multiselect>
            <!-- <pre class="language-json"><code>{{ selectedToEmails }}</code></pre> -->
            <span class="help is-danger"
              v-if="!isValidToEmail">Invalid email address</span>
            <span class="help is-info"
              v-if="showToHint">Press &lt;Enter&gt; key to add email address</span>
          </div>
        </div>
        <div class="control">
          <button class="button"
            @click="toggleCcBcc"
            tabindex="-1">
            <span class="icon is-small is-left">
              <i class="mdi mdi-24px"
                :class="[ showCcBcc ? 'mdi-chevron-up' : 'mdi-chevron-down' ]" />
            </span>
          </button>
        </div>
      </div>
    </div>
    <div v-show="showCcBcc"
      class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Cc</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control is-expanded">
            <!-- <vue-input-tag :tags.sync="innerEmailer.ccEmails"
              @new-tag="newCcEmailInput"
              validate="email" /> -->
            <multiselect id="ccemails-multiselect"
              v-model="selectedCcEmails"
              :options="availableCcEmails"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="true"
              :preserve-search="true"
              :taggable="true"
              placeholder="Enter email address or search"
              tag-placeholder="Press enter to add email"
              label="email"
              track-by="email"
              :show-labels="false"
              @tag="addEmailTag"
              @search-change="getAssetsEmailAddresses"
              :searchable="true"
              :internal-search="false">
              <template slot="option"
                slot-scope="props">
                <span class="tag is-pulled-right"
                  :class="{'is-success' : props.option.assetTypeName === 'Customer', 'is-info' : props.option.assetTypeName === 'Insurer'}">
                  {{ props.option.assetTypeName }}
                </span>
                <span>{{ props.option.name }} &lt;{{ props.option.email }}&gt;</span>
              </template>
              <template slot="tag"
                slot-scope="props">
                <span class="tag is-primary multiselect-email-tag">
                  {{ props.option.email }}
                  <button class="delete is-small"
                    @click="props.remove(props.option)" />
                </span>
              </template>
            </multiselect>
            <!-- <span class="help is-danger"
              v-if="!isValidCcEmail">Invalid email address</span>
            <span class="help is-info"
              v-if="showCcHint">Press &lt;Enter&gt; key to add email address</span> -->

          </div>
        </div>
      </div>
    </div>
    <div v-show="showCcBcc"
      class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Bcc</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control is-expanded">
            <!-- <vue-input-tag :tags.sync="innerEmailer.bccEmails"
              @new-tag="newBccEmailInput"
              validate="email" /> -->
            <multiselect id="bccemails-multiselect"
              v-model="selectedBccEmails"
              :options="availableBccEmails"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="true"
              :preserve-search="true"
              :taggable="true"
              placeholder="Enter email address or search"
              tag-placeholder="Press enter to add email"
              label="email"
              track-by="email"
              :show-labels="false"
              @tag="addEmailTag"
              @search-change="getAssetsEmailAddresses"
              :searchable="true"
              :internal-search="false">
              <template slot="option"
                slot-scope="props">
                <span class="tag is-pulled-right"
                  :class="{'is-success' : props.option.assetTypeName === 'Customer', 'is-info' : props.option.assetTypeName === 'Insurer'}">
                  {{ props.option.assetTypeName }}
                </span>
                <span>{{ props.option.name }} &lt;{{ props.option.email }}&gt;</span>
              </template>
              <template slot="tag"
                slot-scope="props">
                <span class="tag is-primary multiselect-email-tag">
                  {{ props.option.email }}
                  <button class="delete is-small"
                    @click="props.remove(props.option)" />
                </span>
              </template>
            </multiselect>
            <span class="help is-danger"
              v-if="!isValidBccEmail">Invalid email address</span>
            <span class="help is-info"
              v-if="showBccHint">Press &lt;Enter&gt; key to add email address</span>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showCcBcc"
      class="is-divider" />
    <div v-show="false"
      class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">From</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control is-expanded">
            <input type="text"
              class="input"
              placeholder="">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Subject</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control is-expanded">
            <input v-model="innerEmailer.subject"
              type="text"
              class="input"
              placeholder="Subject">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Message</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control is-expanded">
            <textarea v-model="innerEmailer.message"
              class="textarea"
              rows="10"
              placeholder="" />
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Attachments</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control is-expanded">
            <vue-input-tag :tags.sync="innerEmailer.attachments"
              tag-colour="is-turquoise"
              :allow-input="false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FocusInserted } from '@/components/directives'
import VueInputTag from '@/components/VueInputTag'
import EmailService from './EmailService'
import Multiselect from 'vue-multiselect'
import _debounce from 'lodash.debounce'
import { AssetTypes } from '@/enums'
// import _isEmpty from 'lodash/isEmpty'

/* eslint-disable */
const emailValidator = new RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)
/* eslint-enable */

export default {
  name: 'VueEmail',
  directives: {
    FocusInserted
  },
  components: {
    VueInputTag,
    Multiselect
  },
  props: {
    value: null,
    toEmails: {
      type: Array,
      default: () => []
    },
    ccEmails: {
      type: Array,
      default: () => []
    },
    bccEmails: {
      type: Array,
      default: () => []
    },
    subject: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    attachments: {
      type: Array,
      default: () => []
    },
    reportBytes: {
      type: ArrayBuffer,
      default: null
    },
    reportName: {
      type: String,
      default: ''
    },
    reportContentType: {
      type: String,
      default: ''
    },
    quoteImageAttachments: {
      type: Array,
      default: function () {
        return []
      }
    },
    quoteAttachments: {
      type: Array,
      default: function () {
        return []
      }
    },
    assetId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      innerEmailer: null,
      showCcBcc: false,
      isValidToEmail: true,
      showToHint: false,
      isValidCcEmail: true,
      showCcHint: false,
      isValidBccEmail: true,
      showBccHint: false,
      innerAttachments: [],
      selectedToEmails: [],
      selectedCcEmails: [],
      selectedBccEmails: [],
      emailFilter: {
        assetId: this.assetId,
        search: '',
        assetTypes: [AssetTypes.Customer, AssetTypes.Insurer],
        pageIndex: 1,
        pageSize: 20
      },
      availableToEmails: [],
      availableCcEmails: [],
      availableBccEmails: []
    }
  },
  computed: {},
  watch: {
    innerEmailer: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    selectedToEmails: {
      handler: function (newVal, oldVal) {
        this.innerEmailer.toEmails.splice(0, this.innerEmailer.toEmails.length)
        this.innerEmailer.toEmails = this.selectedToEmails.map((e) => e.email)
      },
      deep: true
    },
    selectedCcEmails: {
      handler: function (newVal, oldVal) {
        this.innerEmailer.ccEmails.splice(0, this.innerEmailer.ccEmails.length)
        this.innerEmailer.ccEmails = this.selectedCcEmails.map((e) => e.email)
      },
      deep: true
    },
    selectedBccEmails: {
      handler: function (newVal, oldVal) {
        this.innerEmailer.bccEmails.splice(0, this.innerEmailer.bccEmails.length)
        this.innerEmailer.bccEmails = this.selectedBccEmails.map((e) => e.email)
      },
      deep: true
    }
    // quoteImageAttachments: {
    //   handler: function(newVal, oldVal) {
    //     console.log(_isEmpty(newVal))
    //     this.refreshAttachmentList(newVal)
    //   },
    //   deep: true
    // }
  },
  created() {
    this.innerEmailer = this.value
    this.emailFilter.assetTypes = this.innerEmailer.assetTypes
    this.populateEmails()
    this.refreshAttachmentList()
    this.getAssetsEmailAddresses()
  },
  mounted() {},
  methods: {
    populateEmails() {
      this.innerEmailer.toEmails.forEach((email) =>
        this.selectedToEmails.splice(this.selectedToEmails.length, 1, {
          name: email,
          email: email
        })
      )
      this.innerEmailer.ccEmails.forEach((email) =>
        this.selectedCcEmails.splice(this.selectedCcEmails.length, 1, {
          name: email,
          email: email
        })
      )
      this.innerEmailer.bccEmails.forEach((email) =>
        this.selectedBccEmails.splice(this.selectedBccEmails.length, 1, {
          name: email,
          email: email
        })
      )
    },
    toggleCcBcc() {
      this.showCcBcc = !this.showCcBcc
    },
    async send() {
      this.$showSpinner('Sending...')
      try {
        const formData = new FormData()
        const blob = new Blob([this.reportBytes], { type: this.reportContentType })
        if (this.innerEmailer.attachments.indexOf(this.reportName) > -1) {
          formData.append('report', blob, this.reportName)
        }
        formData.append('emailer', JSON.stringify(this.innerEmailer))
        // if (this.quoteImageAttachments.length > 0) {
        //   console.log(this.quoteImageAttachments)
        // }
        formData.append('imageIds', JSON.stringify(this.quoteImageAttachments.map((i) => i.quoteImageId)))
        formData.append('quoteAttachmentIds', JSON.stringify(this.quoteAttachments.map((i) => i.quoteAttachmentId)))
        // formData.append('images', this.quoteImageAttachments)
        await EmailService.send(formData)
        this.$notification.openNotificationWithType('success', 'Send Email', 'Email sent successfully')
        this.$emit('sent')
      } catch (e) {
        this.$notification.openMessageXhrError('Send Email', e)
      } finally {
        this.$hideSpinner()
      }
    },
    async sendExport(exportOptions, reportOptions) {
      this.$showSpinner('Sending...')
      try {
        const formData = new FormData()
        const blob = new Blob([this.reportBytes], { type: this.reportContentType })
        if (this.innerEmailer.attachments.indexOf(this.reportName) > -1) {
          formData.append('report', blob, this.reportName)
        }
        formData.append('emailer', JSON.stringify(this.innerEmailer))
        // formData.append('images', JSON.stringify(this.quoteImageAttachments))
        formData.append('imageIds', JSON.stringify(this.quoteImageAttachments.map((i) => i.quoteImageId)))
        await EmailService.sendExport(this.innerEmailer.assetId, exportOptions, reportOptions, formData)
        this.$notification.openNotificationWithType('success', 'Send Email', 'Email sent successfully')
        this.$emit('sent')
      } catch (e) {
        this.$notification.openMessageXhrError('Send Email', e)
      } finally {
        this.$hideSpinner()
      }
    },
    newEmailInput(newEmail) {
      this.isValidToEmail = newEmail === '' || emailValidator.test(newEmail)
      this.showToHint = this.isValidToEmail && newEmail !== ''
      this.$emit('new-email', newEmail !== '')
    },
    newCcEmailInput(newEmail) {
      this.isValidCcEmail = newEmail === '' || emailValidator.test(newEmail)
      this.showCcHint = this.isValidCcEmail && newEmail !== ''
      this.$emit('new-email', newEmail !== '')
    },
    newBccEmailInput(newEmail) {
      this.isValidBccEmail = newEmail === '' || emailValidator.test(newEmail)
      this.showBccHint = this.isValidBccEmail && newEmail !== ''
      this.$emit('new-email', newEmail !== '')
    },
    refreshAttachmentList(images) {
      // this.innerEmailer.attachments.splice(0, this.innerEmailer.attachments.length)
      // this.innerEmailer.attachments.splice(this.innerEmailer.attachments.length, 1, this.reportName)
      // // console.log(images)
      // if (!_isEmpty(images)) {
      //   for (var i = 0; i < images.length; i++) {
      //     this.innerEmailer.attachments.splice(this.innerEmailer.attachments.length, 1, images[i].fileName)
      //   }
      // }
    },
    addEmailTag(newEmail, id) {
      const isValidEmail = emailValidator.test(newEmail)
      // console.log('newTag', this.isValidToEmail, newEmail)
      if (isValidEmail) {
        const tag = {
          name: newEmail,
          email: newEmail,
          assetTypeName: 'None',
          assetType: 0
        }
        if (id === 'toemails-multiselect') {
          this.isValidToEmail = isValidEmail
          this.availableToEmails.push(tag)
          this.selectedToEmails.push(tag)
        } else if (id === 'ccemails-multiselect') {
          this.isValidCcEmail = isValidEmail
          this.availableCcEmails.push(tag)
          this.selectedCcEmails.push(tag)
        } else if (id === 'bccemails-multiselect') {
          this.isValidBccEmail = isValidEmail
          this.availableBccEmails.push(tag)
          this.selectedBccEmails.push(tag)
        }
      }
    },
    getAssetsEmailAddresses: _debounce(async function (query, id) {
      // console.log('getAssetsEmailAddresses', query, id)
      if (!this.$guid.validGuid(this.emailFilter.assetId)) {
        this.emailFilter.assetId = this.$guid.empty()
      }
      this.emailFilter.search = query || ''
      if (id === 'toemails-multiselect' || !id) {
        this.availableToEmails = await EmailService.getAssetsEmailAddresses(this.emailFilter)
      } else if (id === 'ccemails-multiselect') {
        this.availableCcEmails = await EmailService.getAssetsEmailAddresses(this.emailFilter)
      } else if (id === 'bccemails-multiselect') {
        this.availableBccEmails = await EmailService.getAssetsEmailAddresses(this.emailFilter)
      }
    }, 300)
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
.multiselect-email-tag {
  margin-right: 0.5rem;
  text-transform: lowercase !important;
}
</style>

<style lang="scss">
#toemails-multiselect,
#ccemails-multiselect,
#bccemails-multiselect {
  text-transform: lowercase !important;
}
</style>

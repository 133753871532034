import FqApi from '@/services/fq-api'

export default {
  async send(formData) {
    const result = await FqApi.post('/emails/send', formData)
    return result
  },
  async sendExport(quoteId, exportOptions, reportOptions, formData) {
    const url = `/emails/sendtext/${quoteId}?exportOptions=${exportOptions}&reportOptions=${reportOptions}`
    const result = await FqApi.post(url, formData)
    return result
  },
  async getAssetsEmailAddresses(filter) {
    const url = 'emails/assetsemails'
    const result = await FqApi.post(url, filter)
    return result.data
  }
}
